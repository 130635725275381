@import "./vars.scss";
.App {
    #main{
        &.page-invoice{
            background-color: $grey_bg;
        }
        #invoices{
            padding:var(--padding-page);
            //background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(248,248,248,1) 8%, rgba(248,248,248,1) 100%);
                    
            .invoiceForm{
                display: flex;
                .calendarWrap{
                    flex: 0 1 calc(50% - 5px);
                    display: flex;
                    position: relative;
                    display: flex;
                    position: relative;
                    & > input{
                        flex: 1 0 calc(50% - 160px);
                    }
                    & > div{
                        position: absolute;
                    }
                    button.clear{
                        font-size: 9px;
                        border:1px solid #ccc;
                        border-radius: 3px;
                        padding:5px 10px;
                        background-color: #f5f5f5;
                    }
                }
            }
                    
        }
    }
}
@import "./vars.scss";
.App {
    #main{
        #profile{
            padding:var(--padding-page);
            .form{
                max-width: 600px;
            }
        }
    }
}

@import "~bootstrap/scss/bootstrap";

$grid-breakpoints: (
  xs: 0,
  xsm: 362px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1800px
);

@import "./vars.scss";
@import "./common.scss";
@import "./Dash.scss";
@import "./Products.scss";
@import "./Invoices.scss";
@import "./Orders.scss";
@import "./Cart.scss";
@import "./Nav.scss";


:root{
    --padding-page:140px 50px;
    --padding-nav:40px 50px;
}

@include media-breakpoint-down(xxxl) {
    :root{
        --padding-page:70px 50px;
        --padding-nav:40px 50px;
    }
}

@include media-breakpoint-down(lg) {
    :root{
        --padding-page:100px 20px 20px 20px;
        --padding-nav:100px 20px 20px 20px;
    }
}

html{
    scrollbar-color: #333 #FFCE00;
    scrollbar-width: thin;
    .App {
        #main{
            position: relative;
            display: flex;
            align-items: stretch;
            flex-wrap: wrap;
            color:$grey_dark;
            margin: 0;
            padding: 0;
            background-color:#fff;
            font-family: 'Roboto', sans-serif;
            font-size: $fs;
            line-height: 1.3 * $fs;
            @include media-breakpoint-down(lg) {
                max-width: 100vw;
                overflow: hidden;
                background-color: $grey_bg;
            }
        }   
    }
}


@media print {
    body * {
        visibility: hidden;
    }

  html, body {
    height:100%; 
    overflow: hidden;
  }

    .modal-body,.modal-body * {
        visibility: visible;
    }

    .modal-body{
        position: absolute;
        left: 0;
        top: 0;
    }
}





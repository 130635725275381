// Common styles

// titles & texts
h1{
    font-weight: bold;
    font-size: 2 * $fs;
    color:$grey_green_dark;
    @include media-breakpoint-down(lg) {
        font-size: 1.3 * $fs; 
    }
    @include media-breakpoint-down(md) {
        font-size: 1.1 * $fs; 
    }
}
p{
    font-size: .9 * $fs;
    font-weight: 500;
    color:$grey_green_dark;
    margin-bottom: 10px;

    @include media-breakpoint-down(lg) {
        font-size: .8 * $fs; 
    }
    @include media-breakpoint-down(md) {
        font-size: .8 * $fs; 
    }
}
small, p.small, span.small{
    font-size: .8 * $fs;
    font-weight: 300;
    color:rgba($grey_green_dark, .6);
}
i{
    color:rgba($grey_green_dark, .4);
}
a{
    text-decoration: none;
    color:$grey_green_dark;
}

.w-10{
    width: 10%;
}
.w-90{
    width: 90%;
}
// forms & tables
input,
select{
    border: 1px solid $grey_medium;
    outline: none;
    background-color: #fff;
    height:54px;
    border-radius: 18px;
    padding-left:20px;
    margin-right: 10px;
    &.loupe{
        background: url(../../public/img/loupe.svg) no-repeat;
        background-position: right 8px center;
    }
    &#date{
        background: #fff url(../../public/img/calgrey.png) no-repeat;
        background-position: right 8px center;
        background-size: 30px;
        padding-right: 45px;
        
    }
}
button.btn-primary{
    border:none;
    padding:10px 15px;
    border-radius: 40px;
    z-index: 99;
    color:#fff;
    font-weight: bold;
    font-size: .9 * $fs;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    background: linear-gradient(90deg, rgba(186,195,44,1) 0%, rgba(255,206,0,1) 100%, rgba(248,248,248,1) 100%);
}
button.btn-secondary{
    background-color: $grey_medium;
    color:$grey_medium_font;
    padding:10px 20px 10px 10px;
    border-radius: 40px;
    border:none;
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 16px;
    img{
        margin-right: 10px;
    }
    @include media-breakpoint-down(lg) {
        width: 55px;
        overflow: hidden;
        position: relative;
        justify-content: flex-start;
        color: transparent;
    }

}
button.btn-transparent{
    background-color: transparent;
    border:none;
}
button[type="submit"]{
    border:none;
    min-width: 160px;
    padding:10px 15px;
    border-radius: 40px;
    color:#fff;
    font-weight: bold;
    font-size: .9 * $fs;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    background: linear-gradient(90deg, rgba(186,195,44,1) 0%, rgba(255,206,0,1) 100%, rgba(248,248,248,1) 100%);
}
.bg-transparent{
    background-color: transparent !important;
    --bs-table-accent-bg:transparent !important;
}
.form-filters{
    display: flex;
    @include media-breakpoint-down(md) {
        flex-direction: column;
        gap:15px;
    }
}

.modal-header{
    border: none;
    .btn-close{
        background: url(../../public/img/close.svg);
        background-size: 36px;
        opacity: 1;
        padding: 10px;
    }
}
.modal-body{
    padding-right: 3%;
    padding-left: 3%;
}
.modal-dialog {
    max-width: 75vw;
    @include media-breakpoint-down(lg) {
        max-width: 100%;
    }
    .total{
        text-align: right;
        font-size: 1.6 * $fs;
        color:$grey_dark;
    } 
}

.modal-backdrop.show {
    opacity: .8;
    //backdrop-filter: blur(10px);
}
.freeInput{
    width: 40px;
    padding: 5px 5px;
    height: auto;
    border-radius: 8px;
    text-align: center;
    font-size: 11px;
    margin: 0;
}
table{
    border-collapse: separate;
    border-spacing: 0 6px;
    @include media-breakpoint-down(lg) {
        border-collapse: collapse;
        border-spacing: 0;
    }
    th, td{
        border: none;
        vertical-align: middle;
        //color:#000000a3;
        color:$grey_dark;
        font-weight: bold;
        b{
            color:$grey_dark;
            text-decoration: underline;
        }
    }
    tr{
        height: 54px;
        th{
            font-size: 13px;
            font-weight: 300;
        }

        @include media-breakpoint-down(lg) {
            display: flex;
            width: 90vw;
            flex-wrap: wrap;
            height: auto;
            min-height: 80px;
            margin-bottom: 10px;
            border-radius: 10px;
            margin-top:20px;
        }
    }
    thead{
        tr{
            height: auto;
        }
    }
    &.table-striped > tbody > tr{

        td{
            &:first-child{
                border-top-left-radius: 15px;
                border-bottom-left-radius: 15px;
            }
            &:last-child{
                border-top-right-radius: 15px;
                border-bottom-right-radius: 15px;
            }
            span.small{
                width: 90px;
                display: block;
            }
        }
        
        & > * {     
            --bs-table-accent-bg: transparent;        
        }
        &:nth-of-type(2n+1){

            & > * {
                --bs-table-accent-bg: #fff;   
            }
            @include media-breakpoint-down(lg) {
                background-color: #fff;
            }
        }
    }
            
}

.modal-body{
    table.table-striped > tbody > tr{
        height: auto;
        & > td {
            color:rgba(0,0,0,.4);
            &:nth-child(2){
                color:rgba(0,0,0,.9);
            }
        }
        &:nth-of-type(2n+1){
            & > td {
                --bs-table-accent-bg: rgba(186,195,44,.1);
            }
            @include media-breakpoint-down(lg) {
                background-color: rgba(186,195,44,.1);
                & > td {
                    --bs-table-accent-bg: transparent;
                }
            }
        } 
    }
}

.dateWrapper{
    z-index: 100;
}
@import "./vars.scss";
.App {
    #main{
        #dash{
            padding:var(--padding-page);
            background-color: white;
            border-top-right-radius: 60px;
            border-bottom-right-radius: 60px;
            box-shadow: 10px 10px 10px #ccc;
            position: relative;
            @include media-breakpoint-down(lg) {
                background-color: transparent;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                box-shadow: none;
            }
            h1{
                padding-bottom:20px;
                color:$grey_green_dark;
                @include media-breakpoint-down(md) {
                    padding-bottom:0px;
                }
            }
            p{
                color:$grey_green_dark;
            }
            & > p, & > div > p{
                padding-top:20px;
            }
            
            .download-app{
                border-radius: 40px;
                border:1px solid #ccc;
                overflow: hidden;
                display: flex;
                color:$grey_green_dark;
                & > div > img{
                    width: 100%;
                    object-fit: cover;
                    min-height: 100%;
                    min-width: 270px;
                }
                .lightflare{
                    box-shadow: inset 0px 0px 10px rgba(255,255,255,0.5);
                }
                div{
                    color:$grey_green_dark;
                    b{
                        font-size: 1.3 * $fs;
                        font-weight: bold;
                        line-height: 25px;
                        margin-bottom: 20px;
                        display: block;
                    }
                    p{
                        font-size: .9 * $fs;
                    }
                }
                small{
                    img{
                        width:84px;
                    }
                }
                @include media-breakpoint-down(sm) {
                    flex-direction: column;
                    align-items: center;
                    & > div{
                        width: 100%;
                    }
                }
                @include media-breakpoint-down(xxl) {
                    @include media-breakpoint-up(lg) {
                        flex-direction: column;
                        align-items: center;
                        & > div{
                            width: 100%;
                        }
                    }
                }
            }
            .follow{
                display: flex;
                img{
                    max-width: 58px;
                }
                @include media-breakpoint-down(lg) {
                    background: #fff;
                    padding: 10px;
                    justify-content: space-around;
                    border-radius: 10px;
                }
            }
            #dash-news{
                padding-top: 20px;
                .wrap{
                    display: flex;
                    gap: 15px;
                    @include media-breakpoint-down(lg) {
                        flex-wrap: nowrap;
                        overflow: auto;
                        width: calc(100% + 50px);
                        padding-right: 50px;
                    }
                }
                p{
                    width: 100%;
                }
                .link-bottom{
                    display: flex;
                    justify-content: right;
                    width: 100%;
                    a{
                        text-decoration: none;
                        padding:10px 0;
                        color:$grey_light;
                        font-size: .9 * $fs;
                        @include media-breakpoint-down(md) {
                            font-size: .8 * $fs; 
                        }
                    }
                }
                .news-banner{
                    width:100%;
                    margin: 0;
                    background-size: cover;
                    background-color: #FFCE00;
                    background-size: cover;
                    background-repeat: no-repeat;
                    min-height: 240px;
                    border-radius: 24px;
                    button{
                        background: rgba(255,255,255,.5);
                        color: #333436;
                        padding: 6px 12px;
                        font-size: 12px;
                    }
                    span{
                        position:relative;
                        color: #fff;
                        b{
                            max-width: 190px;
                            display: block;
                            font-size: 20px;
                            line-height: 27px;
                        }
                    }
                }
                .news-item{
                    flex: 0 0 calc(33.33% - 10px);
                    min-height: 240px;
                    position: relative;
                    padding:20px;
                    display: flex;
                    align-items:flex-end;
                    background-size: cover;
                    background-color: #FFCE00;
                    border-radius: 24px;
                    text-decoration: none;
                    @include media-breakpoint-down(lg) {
                        min-width: 250px;
                    }
                    .picto{
                        position: absolute;
                        top: 10px;
                        right: 10px;
                        width:31px;
                        height:31px;
                        img{
                            width:31px;
                            height:31px;
                        }
                    }
                    span{
                        position:relative;
                        color: #fff;
                    }
                }
            }
        }
        .orderDash{
            &#orders{
                background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(248,248,248,1) 8%, rgba(248,248,248,1) 100%);
                @include media-breakpoint-down(md) {
                    padding-top: 10px !important;
                }
            
            }
            button.btn-secondary{
                //@include media-breakpoint-down(xl) {
                    //width: 55px;
                    overflow: hidden;
                    position: relative;
                    justify-content: center;
                    //color: transparent;
                    padding: 15px;
                //}

            }
            
        }
    }
}
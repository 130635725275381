@import "./vars.scss";
.App {
    #main{
        &.page-order{
            background-color: $grey_bg;
            button.btn-secondary img {
                margin-left: 5px;
            }
        }
        #orders{
            padding:var(--padding-page);
                   
            .orderForm{
                display: flex;
                flex-wrap: nowrap;
                gap:10px;
                button.btn-secondary img {
                    margin-right: 10px; 
                }
                & > input{
                    
                    margin: 0;
                    @include media-breakpoint-down(xl) {
                        flex: 0 1 calc(40% - 5px);
                    }
                    @include media-breakpoint-down(md) {
                        flex: unset;
                    }
                }
                .calendarWrap{
                    flex: 0 1 calc(50% - 5px);
                    display: flex;
                    position: relative;
                    & > input{
                        flex: 1 0 calc(50% - 160px);
                        width: 100%;
                        @include media-breakpoint-down(sm) {
                            font-size: 13px;
                        }
                    }
                    & > div{
                        position: absolute;
                    }
                    button.clear{
                        font-size: 9px;
                        border:1px solid #ccc;
                        border-radius: 3px;
                        padding:5px 10px;
                        background-color: #f5f5f5;
                    }
                }
                @include media-breakpoint-down(xl) {
                    flex-wrap: wrap;
                }
            }
            table{
                tr{
                    &.waiting{
                        & > td:first-child{
                            padding-left:15px;
                            border-left:4px solid #FFCE00;
                        }
                    }
                    @include media-breakpoint-down(sm) {
                        td{
                            width: 100%;
                            span.small {
                                width: auto;
                                display: inline;
                                margin-right: 10px;
                            }
                        }
                        
                    }
                }


                
                
            } 
        }

        .total{
            color:#000;
            font-weight:bold;
        }
    }
}
@import "./vars.scss";
.App {
  #main {
    &[data-info="cartOpened"] #nav {
      box-shadow: none;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
    .close-cart {
      background: none;
      border: none;
      position: absolute;
      top: 30px;
      right: 20px;
      img {
        width: 35px;
      }
    }
    .addtocart,
    .removefromcart {
      border: none;
      background: transparent;
    }
    #cartButton {
      position: fixed;
      top: 30px;
      right: 30px;
      border: none;
      padding: 10px 15px;
      border-radius: 40px;
      z-index: 99;
      color: #fff;
      font-weight: bold;
      font-size: 0.9 * $fs;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      background: linear-gradient(
        90deg,
        rgba(186, 195, 44, 1) 0%,
        rgba(255, 206, 0, 1) 100%,
        rgba(248, 248, 248, 1) 100%
      );
      img {
        margin-right: 8px;
      }
      span:last-child {
        position: absolute;
        top: -8px;
        right: -3px;
        font-size: 9px;
        background-color: black;
        color: #fff;
        border-radius: 20px;
        width: 23px;
        padding: 2px 5px;
      }
      @include media-breakpoint-down(xxl) {
        top: 20px;
        right: 20px;
      }
      @include media-breakpoint-down(md) {
        top: 10px;
        right: 10px;
        background: transparent;
        .name {
          display: none;
        }
        span:last-child {
          position: absolute;
          top: 0px;
          right: 7px;
          font-size: 8px;
          background-color: #333;
          color: #fff;
          border-radius: 20px;
          width: 21px;
          padding: 0;
        }
      }
    }
    
    .cart-wrapper{
      &.open {
        .inner-nav {
          display: block;
        }
      }
      overflow: auto;
      display: none;
      position: absolute;
      top: 0;
      left: 16.666%;
      background: #fff;
      //width: 16.666%;
      width: 25%;
      height: 100%;
      border-top-right-radius: 60px;
      border-bottom-right-radius: 60px;
      box-shadow: 10px 10px 10px #ccc;
      padding: 10% 30px;
      z-index: 999999;
      @media screen and (max-width: 1300px) {
        width: 30%;
      }
      @include media-breakpoint-down(lg) {
        left: 0;
        top: 90px;
        width: 100%;
        height: auto;
        border-radius: 0;
        border-bottom-right-radius: 30px;
        border-bottom-left-radius: 30px;
      }

      &.open {
        display: block;
      }
      h2 {
        font-size: 20px;
        font-weight: bold;
        color: $grey_green_dark;
      }
      #cart {
        .header-cart {
          font-size: 0.8 * $fs;
          color: $grey_green_dark;
          margin-top: 20px;
          span {
            display: inline-block;
            width: 30%;
          }
          & > span:first-child {
            width: 30%;
          }
        }
        .order {
          flex-direction: column;
          .line {
            padding: 5px 0;
            width: 100%;
            display: flex;
            justify-content: space-between;
            div {
              width: 20%;
              font-weight: bold;
              color: rgba($grey_green_dark, 0.7);
              position: relative;
              white-space: nowrap;
              &:first-child {
                color: $grey_green_dark;
              }
              .warning-penalty {
                mask-image: url("../../public/img/triangle-exclamation.svg");
                mask-repeat: no-repeat;
                -webkit-mask-image: url("../../public/img/triangle-exclamation.svg");
                -webkit-mask-repeat: no-repeat;
                mask-size: contain;
                -webkit-mask-size: contain;
                background-color: #ff000090;
                width: 14px;
                height: 14px;
                display: inline-block;
                position: absolute;
                left: -20px;
                top: 3px;
              }
            }
            & > div:first-child {
              width: 30%;
            }
            & > div:last-child {
              width: 10%;
            }
            @include media-breakpoint-down(md) {
              width: auto !important;
            }
          }
          .opener {
            cursor: pointer;
          }
          .products {
            display: none;
            width: 100%;
            flex-direction: column;
            &.open {
              display: flex;
              background: rgba(0, 0, 0, 0.03);
              border-radius: 10px;
              padding: 10px;
            }
          }
          .product {
            div {
              font-size: 0.8 * $fs;
              color: $grey_green_dark;
              width: 10%;
              &:first-child {
                width: 60%;
              }
              &.number {
                padding: 0 5px;
              }
            }
            &.alert-penalty {
              background-color: #ff000035;
              padding: 4px 8px;
              text-align: center;
              &:first-child {
                width: 100%;
                color: #00000090;
              }
            }
          }
        }
        .comments-link {
          font-size: 14px;
          text-decoration: underline;
          display: inline-block;
        }
        #comments-zone {
          display: none;
          flex-direction: column;
          gap: 10px;
          &.show {
            display: flex;
          }
          .comments {
            display: flex;
            flex-direction: column;
            gap: 5px;
            background: rgba(0, 0, 0, 0.03);
            border-radius: 10px;
            padding: 10px;
            label {
              font-size: 14px;
            }
          }
        }
          .delivery-link{
            margin:10px 0;
            display:inline-block;
            text-decoration:underline;
          }
        #delivery-zone {
          display: none;
          flex-direction: column;
          gap: 10px;
          &.show {
            display: flex;
          }
          .delivery{
            display: flex;
            flex-direction: column;
            gap: 5px;
            background: rgba(0, 0, 0, 0.03);
            border-radius: 10px;
            padding: 10px;
            label {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

.App{
  &.reduce-side-bar{
    #cartWrapper{
      &.open{
        left: 50px;
      }
    }
  }
}

.delivery-cart{
  p{
    font-size:14px;
    margin-bottom:0;
  }
}
@import "./vars.scss";

#main.page-auth{
  background-color: #ebca0c;
  #nav{
    display: none;
  }
  #cartButton{
    display: none;
  }
}
#auth{
  padding:var(--padding-page);
  background-color: white;
  border-top-right-radius: 60px;
  border-bottom-right-radius: 60px;
  box-shadow: 10px 10px 10px rgba(0,0,0,0.23);
  position: relative;
  color:$grey_green_dark;
  min-height: 100vh;

  #auth_logo{
    display: flex;
    justify-content: center;
    margin: 30px auto;
    img{
      max-width: 220px;
    }
    @media screen and (max-width:768px) {
      display: none;
    }
  }
  /*.auth-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
  }*/
  .auth_text{
    text-align: center;
    margin:40px auto;
  }
  .auth-form {
    margin:40px auto;
    .form-group{
      position: relative;
      margin-top: 20px;
      margin-bottom: 40px;
      label {
        position: absolute;
        top: -8px;
        left: 25px;
        background-color: #fff;
        font-size: $fs;
        color:$grey_green_dark;
        padding: 2px 5px;
      }
      input[type="email"],
      input[type="text"],
      input[type="password"]{
        height: 72px;
        border-radius: 72px;
        padding-left: 25px;
      }
    }
    
  }
  
  .auth-form-content {
    padding-left: 12%;
    padding-right: 12%;
    @media screen and (max-width:768px) {
      .d-flex{
        justify-content: center !important;
      }
      .forgot-password{
        text-align: center !important;
      }
    }
  }
  
  
  label {
    font-size: 14px;
    font-weight: 600;
    color: rgb(34, 34, 34);
  }

}

#auth_splash{
  background: url("../../public/img/image_login.png") no-repeat;
  background-size: cover;
  background-position: center;
  margin-left: -50px;
  width: calc(58.33333333% + 50px);
}
  
// Navbar
#logo {
  width: 100%;
  height: auto;
  text-align: center;
  display: block;
  margin-bottom: 20px;

  img {
    width: 100%;
    max-width: 230px;
  }
  @include media-breakpoint-down(lg) {
    display: none;
  }
}
#logomobile {
  z-index: 99;
  width: 170px;
  position: absolute;
  top: 0;
  left: calc(50% - 85px);
  img {
    width: 100%;
  }
}
#burger {
  button {
    z-index: 99;
    border: none;
    background-color: #bac32c21;
    padding: 10px;
    position: absolute;
    border-radius: 6px;
    top: 10px;
    left: 10px;
  }
}

#nav {
  min-height: 100vh;
  background-color: #fff;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.24);
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  padding: var(--padding-nav);
  padding-left: 13px;
  padding-right: 7px;
  transition: 300ms ease-in-out;
  position: relative;

  @include media-breakpoint-down(lg) {
    display: none;
    opacity: 0;
    box-shadow: none;
    &.open {
      display: block;
      opacity: 1;
      min-height: 0 !important;
      width:100%;
    }
  }
  .profile {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    gap: 20px;
    padding-bottom: 40px;
    border-bottom: 1px solid $green_light;
    margin-bottom: 10px;
    @include media-breakpoint-down(lg) {
      display: none;
    }
    .profile-img {
      position: relative;
      img {
        max-width: 95px;
      }
      .profile-edit-link {
        position: absolute;
        bottom: 0px;
        right: 0px;
      }
    }

    .profile-info {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      color: $grey_green_dark;
      .name {
        font-size: 1.2 * $fs;
        font-weight: bold;
        text-align: center;
      }
      .email {
        font-size: 0.8 * $fs;
        padding-bottom: 10px;
      }
      button {
        background-color: #f6f7e3d1;
        border-radius: 23px;
        border: none;
        color: $grey_dark;
        font-weight: bold;
        font-size: 0.8 * $fs;
        padding: 8px 17px;
        margin-bottom: 20px;
      }
    }
  }
  .close-nav {
    background-color: transparent;
    border: none;
    color: $grey_green_dark;
    font-size: 0.6 * $fs;
    padding: 8px 17px;
    margin-bottom: 20px;
    img {
      width: 12px;
      margin-right: 5px;
      position: relative;
      top: -1px;
    }
  }
  .menu {
    margin-top: 40px;
    margin-bottom: 20px;

    @include media-breakpoint-down(lg) {
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 20px;
      padding-bottom: 20px;
      background-color: $green_medium;
      position: absolute;
      top: 10px;
      left: 10px;
      z-index: 99;
      width: calc(100% - 20px);
      box-shadow: 0 0 25px rgba(0, 0, 0, 0.14);
      border-radius: 5px;
      & > div {
        position: absolute;
        top: -10px;
        left: -10px;
        img {
          width: 32px;
        }
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 3px;
      @include media-breakpoint-down(lg) {
        flex-direction: row;
        justify-content: center;
      }
      li {
        width: 100%;
        max-width: 215px;
        @include media-breakpoint-down(lg) {
          width: auto;
          max-width: none;
        }
        a {
          text-align: left;
          padding: 10px 10px;
          color: $grey_green_dark;
          border: 2px solid transparent;
          border-radius: 23px;
          border: none;
          display: flex;
          align-items: center;
          @include media-breakpoint-down(lg) {
            flex-direction: column;
          }
          &:hover {
            background-color: $green_medium;
          }
          span {
            display: inline-block;
            width: 40px;
            margin-right: 5px;
            @include media-breakpoint-down(lg) {
              width: auto;
              margin-right: 0;
            }
          }
          span.active {
            display: none;
          }
          div.text {
            @include media-breakpoint-down(lg) {
              font-size: 10px;
              line-height: 13px;
              text-align: center;
              padding-top: 5px;
            }
          }
          &.active {
            border: 2px solid $green;
            color: $green;
            font-weight: bold;
            background-color: transparent;
            @include media-breakpoint-down(lg) {
              border: none;
            }
            span {
              display: none;
            }
            span.active {
              display: inline-block;
            }
          }
        }
      }
    }
  }
  .link {
    background-color: $grey_bg;
    border-radius: 10px;
    max-width: 138px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 50px auto 0 auto;
    padding: 10px;
    @include media-breakpoint-down(lg) {
      margin: 20px auto 0 auto;
      position: absolute;
      width: 130px;
      left: calc(50% - 65px);
      z-index: 100;
      border: 2px solid $green_medium;
      top: 90px;
    }
  }
  .mascotte {
    position: relative;
    bottom: -40px;
    left: -7px;
    width: 70%;
    @include media-breakpoint-down(lg) {
      display: none;
    }
    img {
      width: 100%;
    }
  }
}

.arrow {
  cursor: pointer;
  height: auto;
  position: absolute;
  right: 4px;
  width: 30px;
  top: 26px;
  transform: rotate(180deg);
  @include media-breakpoint-down(lg) {
    display: none;
  }
}

.rotate {
  transform: rotate(0);
}


.inner-nav {
  display: block;
}

.App{
  &.reduce-side-bar{
    .nav-wrapper{
      width: 50px;
      overflow: hidden;
      .inner-nav {
        display: none;
        @media screen and (max-width:990px){
          display:block;
        }
      }

    }
  }
}

.page-dash {
  #nav {
    border-right: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 0;
    box-shadow: unset;
  }
  div#orders {
    padding-top: 10px;
  }
}

@import "./vars.scss";
.App {
  #main {
    &.page-product {
      background-color: $grey_bg;
      @include media-breakpoint-down(lg) {
        .buttons {
          flex: 0 0 100% !important;
          justify-content: space-between;
          button.btn-secondary {
            width: 60px;
          }
          button#bookmarks {
            width: 100%;
            color: #666;
          }
        }
      }
    }
    #products {
      padding: var(--padding-page);
      .ProductForm {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 10px;
        & > select {
          flex: 1 0 calc(25% - 15px);
          margin: 0;
        }
        & > input {
          flex: 1 0 calc(40% - 15px);
          margin: 0;
        }
        & > .buttons {
          flex: 0 0 calc(25% - 15px);
          margin: 0;
          display: flex;
          gap: 10px;
        }
        #bookmarks.btn-secondary {
          img {
            margin-left: -2px;
          }
          &.active {
            background: linear-gradient(
              90deg,
              rgba(186, 195, 44, 1) 0%,
              rgba(255, 206, 0, 1) 100%,
              rgba(248, 248, 248, 1) 100%
            );
          }
        }
      }
      table {
        tr {
          &[data-selected="selected"] {
            //background-color: $green_selected !important;
            td {
              --bs-table-accent-bg: $green_selected !important;
              background-color: $green_selected !important;
              @include media-breakpoint-down(lg) {
                --bs-table-accent-bg: transparent !important;
                background-color: transparent !important;
              }
            }
          }
          button {
            border: none;
            background-color: transparent;
          }
          & > td {
            color: $grey_light;
            padding: 5px 20px;

            @include media-breakpoint-up(md) {
              &:nth-child(2) {
                max-width: 140px;
              }
              &:nth-child(4) {
                max-width: 140px;
              }
              &:nth-child(5) {
                max-width: 140px;
              }
              &:nth-child(6) {
                min-width: 140px;
              }
              &:nth-child(7) {
                min-width: 140px;
              }
              &:nth-child(8) {
                /*display: flex;
                                align-content: center;
                                align-items: center;
                                height: 58px;
                                min-width: 120px;
                                .counter{
                                    padding: 0 10px;
                                }*/
                min-width: 150px;
              }
              &:nth-child(9) {
                min-width: 150px;
              }
              &:nth-child(10) {
                min-width: 130px;
              }
            }
            @include media-breakpoint-down(lg) {
              width: 50%;
              &:first-child {
                width: 100%;
                order: -3;
              }
              &:nth-child(2) {
                order: -1;
              }
              &:nth-child(3) {
                order: -2;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  .info-delivery {
    background: linear-gradient(90deg, #bac32c, #ffce00 100%, #f8f8f8 0);
    width: 100%;
    padding: 20px 12px;
    display: inline-block;
    cursor: pointer;
    margin: 30px 0;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      text-align: center;
      white-space: nowrap;
      margin: 0;
      font-weight: bold;
      color: #fff;
      @media screen and (max-width: 990px) {
        white-space: wrap;
      }
    }
    img {
      margin-left: 15px;
      width: 18px;
      @media screen and (max-width: 990px) {
        width: 15px;
      }
    }
  }
  .supplier-info {
    transition: 200ms;
    background-color: #fff;
    padding: 20px;
    margin-bottom: 20px;
    margin-top: -30px;
    border-radius: 0 0 10px 10px;
    li {
      @media screen and (max-width: 990px) {
        font-size: 14px;
      }
    }
  }

  .btn-img {
    margin-right: 0;
  }
  .btn-p {
    padding: 10px 13px 10px 10px;
  }

  .rotate-info {
    transform: rotate(180deg);
  }
}

.App.reduce-side-bar {
  #products {
    width: calc(100% - 50px);
  }
}

.info-stock {
  color: red;
  font-size: 14px;
  margin-left: 5px;
}

.info-tab {
  font-size: 13px;
  display: inline-block;
  margin-bottom: 3px;
}

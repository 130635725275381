$grey: #ccc;
$grey_green_dark: #6C7B6A;
$grey_dark : #333436;
$grey_light:#6C7B6AAD;
$grey_medium:#E5E5E5;
$grey_medium_font:#707070;
$grey_bg: #F8F8F8;

$green_light: #f0f1de;
$green_medium: #F6F7E3;
$green_selected : #BAC32C21;
$green : #BAC32C;

$fs : 1rem;

